<template>
  <div class="redpack2022">
    <header>
      <square src="https://img.xinshu.me/resource/71de2a57e06e41cca35b55f2a5ba9f9a" plain/>
    </header>
    <div class="redpacks">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="item in redpacks" :key="item.pic">
          <div class="redpack" :style="{backgroundImage: `url(${item.pic})`}">
            <div class="content">
              <div class="user-info">
                <avatar :src="user.avatar" size="2.5em"/>
                {{user.nickname}}
              </div>
              <div class="invite" v-if="inviting === item.id || item.invited">
                <div class="mb-2">
                  {{item.finished ? '您已邀请3位好友，点击下方按钮领取吧' : '邀请3位新用户好友助力即可领取'}}
                </div>
                <div class="user-list">
                  <div class="item" v-for="(i, u) in 3" :key="i">
                    <div class="item-avatar">
                      <img :src="$img(item.invitedUsers[u])" alt="" v-if="item.invitedUsers[u]">
                      <div class="empty" @click="item.remainCount > 0 && showPoster(item.id)"
                           v-else>
                        <fa icon="plus"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="btn btn-theme" v-if="item.finished" @click="claim(item.id)">
                    点击领取红包封面
                  </div>
                  <div class="btn btn-theme" @click="showPoster(item.id)"
                       v-else-if="item.remainCount > 0 || item.invited">
                    邀请好友助力
                  </div>
                  <div class="btn btn-theme disabled" v-else>今天已领完哦，明天再来看看吧</div>
                </div>
              </div>
              <div class="share" v-else-if="expired">
                <div class="py-4 text-white" style="font-size: 1.5em; font-weight: bold;">活动已结束</div>
              </div>
              <div class="share" v-else>
                <div class="share-btn" @click="goInvite(item)">
                  <img src="https://img.xinshu.me/resource/8dd8ca0d31144febb556ac3e890541ce">
                </div>
                <div class="mt-3 text-white">邀请好友助力即可获得该封面哦</div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <popup v-if="poster" :src="poster" @close="poster = ''" tip="发送海报给好友扫码即可助力哦"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'redpack2022',
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      expired: new Date() >= new Date('2022-01-25T00:00:00'),
      swiperOptions: {
        centeredSlides: true,
        initialSlide: 1,
        slidesPerView: 1,
        spaceBetween: 0
      },
      inviting: null,
      poster: '',
      redpacks: [
        {
          id: 'E6F304',
          pic: 'https://img.xinshu.me/resource/e48f921eebec4d529964d7f17fc2c14f',
          remainCount: 1,
          invited: false,
          finished: false,
          invitedUsers: []
        },
        {
          id: '26F916',
          pic: 'https://img.xinshu.me/resource/8537933c25b846b690784836b03d551c',
          remainCount: 1,
          invited: false,
          finished: false,
          invitedUsers: []
        },
        {
          id: '2A9131',
          pic: 'https://img.xinshu.me/resource/9e91e15325004467af4d921c9f57461f',
          remainCount: 1,
          invited: false,
          finished: false,
          invitedUsers: []
        }
      ]
    }
  },
  async created() {
    if (localStorage.lastInviteId) {
      this.swiperOptions.initialSlide = this.redpacks.map(i => i.id).indexOf(localStorage.lastInviteId)
      this.inviting = localStorage.lastInviteId
    }
    const results = await Promise.all(this.redpacks.map(i => {
      return this.$req.get(`/api/activity/redpack_cover/${i.id}/status`)
    }))
    this.redpacks[0] = Object.assign({}, this.redpacks[0], results[0])
    this.redpacks[0].invited = results[0].invitedUsers.length >= 1
    this.redpacks[0].finished = results[0].invitedUsers.length >= 3
    this.redpacks[1] = Object.assign({}, this.redpacks[1], results[1])
    this.redpacks[1].invited = results[1].invitedUsers.length >= 1
    this.redpacks[1].finished = results[1].invitedUsers.length >= 3
    this.redpacks[2] = Object.assign({}, this.redpacks[2], results[2])
    this.redpacks[2].invited = results[2].invitedUsers.length >= 1
    this.redpacks[2].finished = results[2].invitedUsers.length >= 3
    this.$forceUpdate()
  },
  methods: {
    goInvite(item) {
      this.inviting = item.id
    },
    async showPoster(id) {
      localStorage.lastInviteId = id
      const item = this.redpacks.find(i => i.id === id)
      if (!item.ticket) {
        const result = await this.$req.get(`/api/activity/redpack_cover/${id}/ticket`)
        if (!result.ticket) {
          return this.$alert.error('今日已领完哦，明天再来看看吧')
        }
        item.ticket = result.ticket
      }

      this.poster = 'https://canvas.xinshu.me/generate/hongbao.' + id +
        '?nickname=' + encodeURIComponent(this.user.nickname) +
        '&avatar=' + encodeURIComponent(this.user.avatar) +
        '&ticket=' + item.ticket
    },
    async claim(id) {
      const {coverId} = await this.$req.get(`/api/activity/redpack_cover/${id}/cover`)
      location.href = `https://support.weixin.qq.com/cgi-bin/mmsupport-bin/showredpacket?receiveuri=${coverId}&check_type=2#wechat_redirect`
    }
  }
}
</script>

<style>
body[data-page="redpack2022"] {
  background-image: url(https://img.xinshu.me/resource/9647ae7ec1394cee9ac6ddad714a69c1);
  background-size: auto 100%;
  background-repeat: repeat-x;
}
</style>

<style scoped lang="scss">
.redpack2022 {
  padding: 0;
  max-width: 640px;
  overflow: hidden;
  user-select: none;
}

.redpacks {
  position: relative;
  margin-top: -50%;
  padding: 2rem;
  padding-top: 0;
}

.swiper-container {
  max-width: 360px;
  width: 90%;
  overflow: visible;
}

.swiper-slide {
  transform: scale(0.9);
  transition: .3s;

  &.swiper-slide-active {
    transform: scale(1);

    .redpack {
      box-shadow: $box-shadow-lg;
    }
  }
}

.redpack {
  text-align: center;
  background-size: 100% 100%;
  position: relative;
  border-radius: 12px;
  box-shadow: $box-shadow;
  overflow: hidden;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
  }

  .user-info {
    color: #fff;
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    padding: .5rem;
  }

  .user-list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .item {
      width: auto;
      text-align: center;
      padding: 0 .5rem;
      color: #999;

      .item-avatar {
        margin: auto;
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background-color: #eee;
        overflow: hidden;
      }

      .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  .share, .invite {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    padding-top: 3rem;
    text-align: center;

    > * {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 6rem;
      border-radius: 100%;
      box-shadow: 0 320px 0 300px #fff;
    }
  }

  .invite {
    padding-top: 7rem;
  }

  .share:before {
    display: none;
  }

  .share-btn {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  &:after {
    content: '';
    padding-bottom: 165%;
    display: block;
  }
}

.btn-theme {
  background-color: #f0cd95;
  border-radius: 100px;
  color: #000;
  min-width: 12em;
}
</style>
