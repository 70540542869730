import { render, staticRenderFns } from "./Redpack2022.vue?vue&type=template&id=1465c593&scoped=true&"
import script from "./Redpack2022.vue?vue&type=script&lang=js&"
export * from "./Redpack2022.vue?vue&type=script&lang=js&"
import style0 from "./Redpack2022.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Redpack2022.vue?vue&type=style&index=1&id=1465c593&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1465c593",
  null
  
)

export default component.exports